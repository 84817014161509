export enum Experiment {
  EnableSectionsTabInMobileAS = 'specs.membersAboutOOI.enableSectionsMobileAS',
  EnableOOIInEditor = 'specs.membersArea.framelessEditorProfilePage',
  EnableCSSOptimization = 'specs.membersAboutOOI.EnableCSSOptimization',
  AboutPublishValidation = 'specs.about.MembersAboutSpecs$ValidateLinksInAboutContent',
  UseMembersAboutV2 = 'specs.membersArea.useMembersAboutV2',
  BlogLiveSiteEditingDeprecation = 'specs.wixBlog.LiveSiteEditorDeprication',
  EnableDesignTabResetButtonPerPage = 'specs.membersAboutOOI.EnableDesignTabResetButtonPerPage',
  EnableMobileDesignTabSectionHeaders = 'specs.membersAboutOOI.EnableMobileDesignTabSectionHeaders',
  EnableButtonBorderSettings = 'specs.membersAboutOOI.EnableButtonBorderSettings',
  EnableMobileTitleSetting = 'specs.membersAboutOOI.EnableMobileTitleSetting',
  DisableButtonOnPublish = 'specs.membersAboutOOI.DisableButtonOnPublish',
  EnablePostListInSEO = 'specs.membersAbout.EnablePostListInSEO',
  EnableRicosContentInSEO = 'specs.membersAbout.EnableRicosContentInSEO',
}
